import React, { useEffect, useState } from "react";
import WifiSchedule from "./wifiSchedule";
import UrlFiltering from "./urlFiltering";
import TrafficPolicies from "./trafficPolicies";
import FastRoaming from "./fastRoaming";
import LoadBalancing from "./loadBalancing";
import VlanManagement from "./vlanManagement";
import { useNavigate } from "react-router-dom";
import AddSSIDModal from "./addSSIDModal";
import errorIcon from "../../../../assets/images/error-2.png";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";

function WifiSetting() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("wifi");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [SSID, setSSID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [wlanId,setWlanId]= useState("");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    getSSIDlist();
  }, []);

  const tabTitles = {
    wifi: "WiFi Settings",
    schedule: "WiFi Schedules",
    filtering: "URL Filtering",
    roaming: "Fast Roaming",
    balancing: "Load Balancing",
    management: "VLAN Management",
    policies: "Traffic Policies"
  };

  const currentTitle = tabTitles[activeTab] || "WiFi Settings";

  const getSSIDlist = async () => {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/configuration/getAllSsids?networkId=6001"
      );
      if (response) {
        setSSID(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch SSID list", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchSSIDDetails = async (wnId) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/configuration/getSsid?wnId=${wnId}`
      );
      if (response) {
        const ssidDetails = response.data;
        navigate(`/wifi-settings`, { state: { ssidDetails } });
      }
    } catch (error) {
      console.error("Failed to fetch SSID details", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSSID = async (wnId) => {
    try {
      setLoading(true);
      const api = new allApi();
      await api.deleteRequest(
        `api/wireless/configuration/deleteSsid?wnId=${wnId}`
      );
      await getSSIDlist();
    } catch (error) {
      console.error("Failed to delete SSID", error);
    } finally {
      setLoading(false);
    }
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openScheduleModal = () => setIsScheduleModalOpen(true);
  const closeScheduleModal = () => setIsScheduleModalOpen(false);

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>{currentTitle}</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              onClick={() => {
                navigate("/wireless");
              }}
            >
              Quick View
            </button>
            {(activeTab === "wifi" || activeTab === "schedule") && (
              <button
                className="icon-btn"
                title="Add"
                onClick={activeTab === "wifi" ? openModal : openScheduleModal}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            )}


            {/* <button title="Add" 
                className={`icon-btn ${activeTab === "schedule" ? "show" : "hide"}`}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button> */}
          </div>
        </div>
        <div className="inc-card-body">
          <div className="sideTab-container">
            <div className="inc-side-tab">
              <ul className="nav-side" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "wifi" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("wifi")}
                    role="tab"
                    aria-controls="pills-wifi"
                    aria-selected={activeTab === "wifi"}
                  >
                    WiFi{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "schedule" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("schedule")}
                    role="tab"
                    aria-controls="pills-schedule"
                    aria-selected={activeTab === "schedule"}
                  >
                    WiFi Schedules{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "filtering" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("filtering")}
                    role="tab"
                    aria-controls="pills-filtering"
                    aria-selected={activeTab === "filtering"}
                  >
                    URL Filtering{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "policies" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("policies")}
                    role="tab"
                    aria-controls="pills-policies"
                    aria-selected={activeTab === "policies"}
                  >
                    Traffic Policies{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "roaming" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("roaming")}
                    role="tab"
                    aria-controls="pills-roaming"
                    aria-selected={activeTab === "roaming"}
                  >
                    Fast Roaming{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "balancing" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("balancing")}
                    role="tab"
                    aria-controls="pills-balancing"
                    aria-selected={activeTab === "balancing"}
                  >
                    Load Balancing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "management" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("management")}
                    role="tab"
                    aria-controls="pills-management"
                    aria-selected={activeTab === "management"}
                  >
                    VLAN Management{" "}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div className="inc-side-tabcontainer">
              <div className="tab-content" id="pills-tabContent">
                {activeTab === "wifi" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-wifi"
                    role="tabpanel"
                    aria-labelledby="pills-wifi-tab"
                  >
                    {/* =============WIFI================ */}
                    <div className="inc-card-table">
                      <table className="full-table m-head">
                        <thead>
                          <tr>
                            <th>SSID</th>
                            <th>Band</th>
                            <th>Security</th>
                            <th>MAC ACL</th>
                            <th>Customer Profile</th>
                            <th>MPSK</th>
                            <th>Rate Limiting</th>
                            <th>VLAN ID</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {loading ? (
                          <tr>
                            <td colSpan="6">
                              <Loader />
                            </td>
                          </tr>
                        ) : SSID?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan="9">
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "medium",
                                  }}
                                >
                                  <p>No SSID To Display</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {SSID?.map((wifi, index) => (
                              <tr key={index}>
                                <td>{wifi?.ssid}</td>
                                <td>{wifi?.band}
                                  {/* <div className="band-pills">
                                    {wifi?.band.includes("2.4GHZ") && (
                                      <span className="yellow">2.4GHz</span>
                                    )}
                                    {wifi?.band.includes("5GHz") && (
                                      <span className="blue">5GHz</span>
                                    )}
                                  </div> */}
                                </td>
                                <td>{wifi?.securityAuthentication || "N/A"}</td>
                                <td>
                                  <span
                                    className={
                                      "d-status " +
                                      (wifi?.broadcastStatus === 1
                                        ? "online"
                                        : "offline")
                                    }
                                  >
                                    {wifi?.broadcastStatus === 1
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </td>
                                <td>N/A</td>{" "}
                                <td>
                                  <span
                                    className={
                                      "d-status " +
                                      (wifi?.fastRoamingSt === 1
                                        ? "online"
                                        : "offline")
                                    }
                                  >
                                    {wifi?.fastRoamingSt === 1
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </td>
                                <td>N/A</td>
                                <td>{wifi?.vlanId || "N/A"}</td>
                                <td>
                                  <span
                                    className={
                                      "d-status " +
                                      (wifi?.broadcastStatus === 1
                                        ? "offline"
                                        : "online")
                                    }
                                  >
                                    {wifi?.broadcastStatus === 1
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                </td>
                                <td>
                                  <div className="button-group">
                                    <button
                                      className="icon-btn"
                                      title="Edit"
                                      onClick={() =>
                                        fetchSSIDDetails(wifi.wnId)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="icon-btn"
                                      title="Delete"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteSsid"
                                      onClick={()=>setWlanId(wifi.wnId)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
                {activeTab === "schedule" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-schedule"
                    role="tabpanel"
                    aria-labelledby="pills-schedule-tab"
                  >
                    {/* =============WiFi Schedules================ */}


                    <div>
                      <p>A WiFi schedule allows you to set the hours when WiFi is available.</p>
                      {isScheduleModalOpen && (
                        <WifiSchedule />
                      )}
                      {/* <button onClick={closeScheduleModal}>Close</button> */}
                    </div>

                  </div>
                )}
                {activeTab === "filtering" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-filtering"
                    role="tabpanel"
                    aria-labelledby="pills-filtering-tab"
                  >
                    {/* =============URL Filtering================ */}
                    <UrlFiltering />
                  </div>
                )}
                {activeTab === "policies" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-policies"
                    role="tabpanel"
                    aria-labelledby="pills-policies-tab"
                  >
                    {/* =============URL Filtering================ */}
                    <TrafficPolicies />
                  </div>
                )}
                {activeTab === "roaming" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-roaming"
                    role="tabpanel"
                    aria-labelledby="pills-roaming-tab"
                  >
                    {/* =============Fast Roaming================ */}
                    <FastRoaming />
                  </div>
                )}
                {activeTab === "balancing" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-balancing"
                    role="tabpanel"
                    aria-labelledby="pills-balancing-tab"
                  >
                    {/* =============Load Balancing================ */}
                    <LoadBalancing />
                  </div>
                )}
                {activeTab === "management" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-management"
                    role="tabpanel"
                    aria-labelledby="pills-management-tab"
                  >
                    {/* =============VLAN Management================ */}
                    <VlanManagement />
                  </div>
                )}
                <div
                  className={`modal fade`}
                  id="deleteSsid"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content inc-modal">
                      <div className="modal-body success-msg">
                        <img src={errorIcon} alt="error" />
                        <p>Are you sure to delete {wlanId} SSID?</p>
                        <button
                          className="text-btn primary-btn"
                          data-bs-dismiss="modal"
                          onClick={() => deleteSSID(wlanId)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <AddSSIDModal getList={getSSIDlist}/>}
    </div>
  );
}

export default WifiSetting;
