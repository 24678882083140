import React from "react";
import internetOpenImage from "../../../assets/images/internet_open.svg";
import routerOpenImage from "../../../assets/images/router_open.svg";
import clientsGenericImage from "../../../assets/images/clients_generic.svg";
import topoLogy5 from "../../../assets/images/topology-5.png";
import apImage from "../../../assets/images/ap_open.svg";
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import towerIconSvg from "./tower.svg";
import deviceone from "../../../assets/images/r1.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";
import { useEffect, useState } from "react";
import ReactFlow, { Controls, MiniMap, Background, useNodesState, useEdgesState } from 'reactflow';
import 'reactflow/dist/style.css';

const topNodes = [
  { id: 'parent', label: 'Tower', position: [21.1458, 79.0882], icon: towerIconSvg }, // Parent Node (Tower)
  { id: '1', label: 'Router 1', position: [28.4595, 77.0266], icon: deviceone }, // Gurugram
  { id: '2', label: 'Router 2', position: [28.6139, 77.2090], icon: devicefour }, // New Delhi
  { id: '3', label: 'Router 3', position: [12.9716, 77.5946], icon: devicethree }, // Bengaluru
  { id: '4', label: 'Router 4', position: [19.0760, 72.8777], icon: deviceone }, // Mumbai
  { id: '5', label: 'Router 5', position: [36.7783, -119.4179], icon: deviceone }, // California
  { id: '6', label: 'Router 6', position: [14.0583, 108.2772], icon: devicethree }, // Vietnam
  { id: '7', label: 'Router 7', position: [9.9312, 76.2673], icon: devicefour }, // Kochi
  { id: '8', label: 'Router 8', position: [23.0225, 72.5714], icon: devicethree }, // Ahmedabad
];

const topEdges = [
  { id: 'e-parent-1', source: 'parent', target: '1', color: '#FF5733' },
  { id: 'e-parent-2', source: 'parent', target: '2', color: '#33FF57' },
  { id: 'e-parent-3', source: 'parent', target: '3', color: '#3357FF' },
  { id: 'e-parent-4', source: 'parent', target: '4', color: '#FF33A1' },
  { id: 'e-parent-5', source: 'parent', target: '5', color: '#FF5733' },
  { id: 'e-parent-6', source: 'parent', target: '6', color: '#33FF57' },
  { id: 'e-parent-7', source: 'parent', target: '7', color: '#3357FF' },
  { id: 'e-parent-8', source: 'parent', target: '8', color: '#FF33A1' },
];

const initialNodes = [
  {
    id: '1',
    type: 'input', // Root node
    data: { label: 'Edge' },
    position: { x: 50, y: 100 },
    style: { background: '#D3D3D3', padding: '10px', borderRadius: '5px' },
  },
  {
    id: '2',
    data: { label: 'D22FF-GGN-1310' },
    position: { x: 250, y: 100 },
    style: { background: '#D3D3D3', padding: '10px', borderRadius: '5px' },
  },
  {
    id: '3',
    data: { label: 'd22-ggn-sw' },
    position: { x: 450, y: 50 },
    style: { background: '#90EE90', padding: '10px', borderRadius: '5px' },
  },
  {
    id: '4',
    data: { label: 'd22-gga-ad' },
    position: { x: 450, y: 150 },
    style: { background: '#F08080', padding: '10px', borderRadius: '5px' },
  },
  {
    id: '5',
    data: { label: 'vvdn_test' },
    position: { x: 650, y: 50 },
    style: { background: '#D3D3D3', padding: '10px', borderRadius: '5px' },
  },
  {
    id: '6',
    data: { label: 'CNM_NDA' },
    position: { x: 650, y: 150 },
    style: { background: '#D3D3D3', padding: '10px', borderRadius: '5px' },
  },
];

const initialEdges = [
  { id: 'e1-2', source: '1', target: '2'},
  { id: 'e2-3', source: '2', target: '3' },
  { id: 'e2-4', source: '2', target: '4' },
  { id: 'e3-5', source: '3', target: '5' },
  { id: 'e3-6', source: '3', target: '6' },
];

const CustomPolyline = ({ source, target, color }) => {
  const midPoint = [
    (source[0] + target[0]) / 2,
    (source[1] + target[1]) / 2 + 1,
  ];

  const curvePoints = [source, midPoint, target];

  return <Polyline positions={curvePoints} color={color} weight={2} smoothFactor={2} />;
};

function Topology() {
  const [activeTab, setActiveTab] = useState("snmp");
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  useEffect(() => {
    setActiveTab("snmp");
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const icon = (iconUrl) => L.icon({
    iconUrl: iconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  const getPositionById = (id) => {
    const node = topNodes.find((node) => node.id === id);
    return node ? node.position : [0, 0]; // Fallback in case position is undefined
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="discoverTab" role="tablist">
              <li className="nav-item" role="discover">
                <button
                  className={`nav-link ${activeTab === "snmp" ? "active" : ""}`}
                  id="snmp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#snmp-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="snmp-tab-pane"
                  aria-selected={activeTab === "snmp" ? "true" : "false"}
                  onClick={() => handleTabChange("snmp")}
                >
                  SNMP
                </button>
              </li>
              <li className="nav-item" role="discover">
                <button
                  className={`nav-link ${activeTab === "https" ? "active" : ""}`}
                  id="https-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#https-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="https-tab-pane"
                  aria-selected={activeTab === "https" ? "true" : "false"}
                  onClick={() => handleTabChange("https")}
                >
                  HTTPS
                </button>
              </li>
            </ul>
          </div>
          <div className="inc-card-body">
            <div className="tab-content mt-4" id="discoverTabContent">
              <div
                className={`tab-pane fade ${activeTab === "snmp" && "active show"
                  }`}
                id="snmp-tab-pane"
                role="tabpanel"
              >
                {activeTab === "snmp" && (
                  <div className="topology-draft">
                    <div style={{ height: "100vh" }}>
                      <MapContainer center={[21.1458, 79.0882]} zoom={5} style={{ height: "80%", width: "100%" }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        {/* Nodes */}
                        {topNodes.map((node) => (
                          <Marker key={node.id} position={node.position} icon={icon(node.icon)}></Marker>
                        ))}

                        {/* Edges */}
                        {topEdges.map((edge) => {
                          const sourcePosition = getPositionById(edge.source);
                          const targetPosition = getPositionById(edge.target);
                          return (
                            <CustomPolyline
                              key={edge.id}
                              source={sourcePosition}
                              target={targetPosition}
                              color={edge.color}
                            />
                          );
                        })}
                      </MapContainer>
                    </div>
                    <div style={{ height: '100vh' }}>
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        fitView
                      >
                      </ReactFlow>
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-pane fade" id="https-tab-pane" role="tabpanel">
                {activeTab === "https" && (
                  <div className="topology-draft">
                    <div style={{ height: "100vh" }}>
                      <MapContainer center={[21.1458, 79.0882]} zoom={5} style={{ height: "80%", width: "100%" }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        {/* Nodes */}
                        {topNodes.map((node) => (
                          <Marker key={node.id} position={node.position} icon={icon(node.icon)}></Marker>
                        ))}

                        {/* Edges */}
                        {topEdges.map((edge) => {
                          const sourcePosition = getPositionById(edge.source);
                          const targetPosition = getPositionById(edge.target);
                          return (
                            <CustomPolyline
                              key={edge.id}
                              source={sourcePosition}
                              target={targetPosition}
                              color={edge.color}
                            />
                          );
                        })}
                      </MapContainer>
                    </div>
                    <div style={{ height: '100vh' }}>
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        fitView
                      >
                      </ReactFlow>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topology;
