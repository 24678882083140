import React, { useState, useEffect } from "react";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function AddSSIDModal({ getList }) {
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showData, setShowData] = useState(false);
  const [showPopUp, setShowPopUp] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [formData, setFormData] = useState({
    ssid: "",
    broadcastStatus: "0",
    band: [],
    vlanId: "WPA2 Personal",
    bandSteeringSt: "0",
    fastRoamingSt: "0",
    clientIsolation: "0",
    AllowLocalUIAccess: "0",
    scheduleEnabled: "0",
    support802_11k_v: "0",
    securityAuthentication: "WPA2 Personal",
  });
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "band") {
        if (value === "All") {
          setFormData({
            ...formData,
            band: checked ? ["2.4 GHz", "5 GHz"] : [],
          });
        } else {
          setFormData((prevData) => {
            const newBand = checked
              ? [...prevData.band, value].filter((v) => v !== "All")
              : prevData.band.filter((band) => band !== value);
            return {
              ...prevData,
              band: newBand,
            };
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: checked ? "1" : "0",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e, actionType) => {
    e.preventDefault();
    try {
      let payload = {
        ssid: formData.ssid,
        broadcastStatus: formData.broadcastStatus,
        band: formData.band.join(", "),
        vlanId: formData.vlanId,
        bandSteeringSt: formData.bandSteeringSt,
        fastRoamingSt: formData.fastRoamingSt,
        clientIsolation: formData.clientIsolation,
        AllowLocalUIAccess: formData.AllowLocalUIAccess,
        scheduleEnabled: formData.scheduleEnabled,
        support802_11k_v: formData.support802_11k_v,
        securityAuthentication: formData.securityAuthentication,
      };
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        payload
      );
      if (response.message) {
        setShowPopUp(false);
        setShowData(true);
        if (actionType === "saveAndConfigure") {
          navigate(`/wifi-settings`);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleSuccessClose = async () => {
    setShowData(false);
    //await getList();
    window.location.reload();
  }
  const handlePopUpClose = () => {
    setShowPopUp(false);
    window.location.reload();
    navigate("/wifi-config");
  }

  const addTimeSlot = (day) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].push({ startTime: "", endTime: "" });
    setSchedule(updatedSchedule);
  };

  const removeTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    setSchedule(updatedSchedule);
  };
  const handleScheduleChange = (day, index, field, value) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day][index][field] = value;
    setSchedule(updatedSchedule);
  };

  const handlePopUp = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "band") {
        if (value === "All") {
          setFormData({
            ...formData,
            band: checked ? ["2.4 GHz", "5 GHz"] : [],
          });
        } else {
          setFormData((prevData) => {
            const newBand = checked
              ? [...prevData.band, value].filter((v) => v !== "All")
              : prevData.band.filter((band) => band !== value);
            return {
              ...prevData,
              band: newBand,
            };
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: checked ? "1" : "0",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setPopUp((prevState) => !prevState);
  }

  return (
    <>
      {showPopUp &&
        <div>
          <div className="customModal">
            <div className="c-modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New SSID</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handlePopUpClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="add-ssid-body">
                      <h4>Wireless Settings</h4>
                      <div className="row d-flex">
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            name="ssid"
                            placeholder="SSID"
                            value={formData.ssid}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="switch-box">
                            <strong>Broadcast SSID</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="broadcastStatus"
                                checked={formData.broadcastStatus === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Band</h4>
                      <p>
                        Some legacy clients may not support WPA3 security. Please
                        select WPA3 Personal <br />
                        Mixed (WPA2+WPA3) mode.
                      </p>
                      <div className="row">
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="All"
                              checked={formData.band.length === 2}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>All</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="2.4 GHz"
                              checked={formData.band.includes("2.4 GHz")}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>2.4 GHz</strong>
                          </label>
                        </div>
                        <div className="col-lg-3 checkbox-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="band"
                              value="5 GHz"
                              checked={formData.band.includes("5 GHz")}
                              onChange={handleChange}
                            />
                            <span className="indicator"></span>
                            <strong>5 GHz</strong>
                          </label>
                        </div>
                      </div>

                      <div className="border-line"></div>

                      <h4 className="mt-3">Security Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="security" className="col-form-label">
                            Security<span className="text-danger">*</span>
                          </label>
                          <select
                            id="securityAuthentication"
                            className="form-select"
                            name="securityAuthentication"
                            placeholder="Select Security"
                            value={formData.securityAuthentication}
                            onChange={handleChange}
                          >
                            <option value="WPA2 Personal">WPA2 Personal</option>
                            <option value="WPA2 Personal Mixed">
                              WPA2 Personal Mixed
                            </option>
                            <option value="WPA3 Personal">WPA3 Personal</option>
                            <option value="WPA3 Personal Mixed">
                              WPA3 Personal Mixed (WPA2 + WPA3)
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="border-line"></div>
                      <h4 className="mt-3">Network Settings</h4>
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="vlanId" className="col-form-label">
                            VLAN ID<span className="text-danger">*</span>
                          </label>
                          <select
                            id="vlanId"
                            className="form-select"
                            name="vlanId"
                            value={formData.vlanId}
                            onChange={handleChange}
                          >
                            <option>WPA2 Personal</option>
                            <option>WPA2 Personal 22</option>
                          </select>
                        </div>
                      </div>
                      <div className="border-line"></div>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Client Isolation</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="clientIsolation"
                                checked={formData.clientIsolation === "1"}
                                onChange={handleChange}
                              />{" "}
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="border-line"></div>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Access To Local UI</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="AllowLocalUIAccess"
                                checked={formData.AllowLocalUIAccess === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="border-line"></div>
                      <h4 className="mt-3 mb-4">Radio Settings</h4>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Band Steering</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="bandSteeringSt"
                                checked={formData.bandSteeringSt === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>Fast Roaming (802.11r)</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="fastRoamingSt"
                                checked={formData.fastRoamingSt === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="switch-box">
                            <strong>802.11k/v</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="support802_11k_v"
                                checked={formData.support802_11k_v === "1"}
                                onChange={handleChange}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="border-line"></div>
                      <div className="row mt-3">
                        <div className="col-lg-12">
                          <div className="switch-box">
                            <strong>Enable SSID Schedule</strong>
                            <label className="ice-switch">
                              <input
                                type="checkbox"
                                name="scheduleEnabled"
                                checked={formData.scheduleEnabled === "1"}
                                onChange={handlePopUp}
                              />
                              <span className="slider"></span>
                            </label>
                             {popUp && (

                        <div className="schedule-week">

                          <h4>Schedule Name</h4>
                          <div className="row d-flex">
                            <div className="col-lg-6">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Schedule Name"
                              />
                            </div>
                          </div>
                          <div className="border-line"></div>
                          <p>Location Timezone - America/New_York (ET)</p>
                          <h4>Days</h4>
                          <p>You can add a maximum of 3 time slots per day.</p>

                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Monday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Tuesday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Wednesday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Thursday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Friday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Saturday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                            </div>
                          </div>
                          <div className="schedule-days">

                            <div className="sc-day-title">
                              <h4>Sunday</h4>
                              <div>
                                <button className="icon-btn">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>

                            <div className="schedule-time">

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>

                              <div className="schedule-time-box">
                                <div className="start-time">
                                  <h4>Start Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <div className="end-time">
                                  <h4>End Time</h4>
                                  <input type="text" placeholder="00:00" />
                                </div>
                                <button>
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>


                            </div>
                          </div>
                        </div>
                      )}

                          </div>

                        </div>

                      </div>
                     

                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={handlePopUpClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="text-btn primary-btn"
                        onClick={(e) => handleSubmit(e, "save")}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="text-btn primary-btn"
                        onClick={(e) => handleSubmit(e, "saveAndConfigure")}
                      >
                        Save & Configure
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Modal
        show={showData}
        onHide={handleSuccessClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>
          SSID created Successfully!
        </p>
        <Button
          onClick={() => handleSuccessClose()}
        >
          OK
        </Button>
      </Modal>
    </>
  );
}

export default AddSSIDModal;
