import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ClientDataExpandation() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [ClientDataCategories, setClientDataCategories] = useState([]);
  const [ClientDataConsumptionDuration, setClientDataConsumptionDuration] =
    useState("24h");
  const [series, setSeries] = useState([]);
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [ClientSeries, setClientSeries] = useState([]);
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");
  const [networkId, setNetworkId] = useState("all");

  const getClientDataConsumption = async (ClientDataConsumptionDuration) => {
    try {
        setLoading(true);
        const api = new allApi();
        const response = await api.getRequest(
            `api/wireless/clientDataConsumption?commandType=5&duration=${ClientDataConsumptionDuration}&networkId=6001`
        );

        if (response.status === "OK" && response.data) {
            const data = response.data;
            const series = [];

            data.forEach((client) => {
                const consumptionData = {
                    name: `${client.deviceName} (SN: ${client.serialNumber})`,
                    data: []
                };

                // Iterate through the consumption entries
                if (client.consumption) {
                    client.consumption.forEach((entry) => {
                        const timestamp = new Date(entry.timestamp * 1000).toISOString();
                        const totalConsumption = entry.totalConsumption;

                        // Add to series data
                        consumptionData.data.push({
                            x: timestamp,
                            y: totalConsumption
                        });
                    });
                }

                series.push(consumptionData);
            });

            // Sorting the data by timestamp if needed
            series.forEach(clientSeries => {
                clientSeries.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });

            setClientDataCategories(series[0]?.data.map(point => point.x)); // Assuming at least one client exists
            setClientSeries(series);
        } else {
            console.error("Failed to fetch data:", response.message);
        }
    } catch (error) {
        console.error("Error fetching connected client data:", error);
    } finally {
        setLoading(false);
    }
};

const clientDataConsumptionOptions = {
  chart: {
      type: "line",
      height: 350,
      toolbar: {
          show: false,
      },
  },
  xaxis: {
      type: "datetime",
      categories: ClientDataCategories,
      labels: {
          formatter: (value) => {
              const date = new Date(value);

              if (ClientDataConsumptionDuration === '7d' || ClientDataConsumptionDuration === '30d') {
                  const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
                  return date.toLocaleString('en-IN', options);
              } else {
                  const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
                  return date.toLocaleString('en-IN', options);
              }
          },
          style: {
              colors: '#9aa0ac',
              fontSize: '12px',
          },
      },
  },
  yaxis: {
      min: 0,
      labels: {
          formatter: function (val) {
              return val !== undefined && val !== null ? val.toFixed(0) : "0 bytes";
          },
      },
      title: {
          text: "Data Consumption (bytes)",
      },
  },
  stroke: {
      width: 1,
  },
  dataLabels: {
      enabled: false,
  },
  legend: {
      show: false, // Set to false to remove the legend
  },
  colors: ClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
  ),
  zoom: {
      enabled: false,
  },
  toolbar: {
      show: false,
  },
};

  useEffect(() => {
    getClientDataConsumption(ClientDataConsumptionDuration);
  }, [ClientDataConsumptionDuration]);

  const handleClientDataConsumptionDurationChange = (ClientDataConsumptionDuration) => {
    setClientDataConsumptionDuration(ClientDataConsumptionDuration);
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Client Data Consumption
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {ClientDataConsumptionDuration === "24h"
                      ? "Last 24 Hrs"
                      : ClientDataConsumptionDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ClientSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={clientDataConsumptionOptions}
                        series={ClientSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th>Serial No</th>
                          <th>Data Consumption</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ClientSeries && ClientSeries.length > 0 &&
                          ClientSeries.map((seriesItem, index) => {
                            const [deviceName, serialNumber] = seriesItem.name.split(' (SN: ');
                            return (
                              <tr key={index}>
                                <td>{deviceName}</td>
                                <td>{serialNumber.replace(')', '')}</td>
                                <td>
                                  {seriesItem.data.reduce((total, item) => total + item.y, 0)}
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDataExpandation;
