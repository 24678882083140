import React, { useState, useEffect } from "react";
import ConfigNet from "./ConfigNet";
import WiredSettings from "./WiredSettings";
import IpConfiguration from "./IpConfiguration";
import WirelessSettings from "./WirelessSettings";
import allApi from "../../../../api/allApi";

function ConfigureNetwork() {
  const [currentStep, setCurrentStep] = useState(1);
  const [configNetData, setConfigNetData] = useState({});
  const [wiredSettingsData, setWiredSettingsData] = useState(null);
  const [wirelessSettingsData, setWirelessSettingsData] = useState({});
  const [ipConfigurationData, setIpConfigurationData] = useState({});
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [formData, setFormData] = useState({
    vlanId: "",
    serialNumber: "",
    taggedPorts: "",
    untaggedPorts: "",
  });
  const [loading, setLoading] = useState(true);
  const [ssidList, setSsidList] = useState([]);
  const [basicSwitchInfo, setBasicSwitchInfo] = useState(null); 
  const [refreshing, setRefreshing] = useState(true);
  useEffect(() => {
    fetchSwitchInfo();
  }, []);

  const fetchSwitchInfo = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const switchInfoResponse = await api.getRequest("api/vlan/get/allSwitchBasicInfo");
      console.log(switchInfoResponse, "info");
      if (switchInfoResponse && switchInfoResponse.portMembers && switchInfoResponse.portMembers.length > 0) {
        const serialNumber = switchInfoResponse.portMembers[0].serialNo;
        setFormData((prev) => ({ ...prev, serialNumber }));
        setBasicSwitchInfo(switchInfoResponse); 
      }
    } catch (error) {
      console.error("Failed to fetch switch info:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch SSID list
  useEffect(() => {
    const fetchSsidList = async () => {
      try {
        setLoading(true);
        setRefreshing(true);
        const api = new allApi();
        const response = await api.getRequest(
          "api/wireless/configuration/getAllSsids?networkId=6001"
        );
        if (response.ok) {
          const responseData = await response.json();
          setSsidList(responseData.data);
        } else {
          throw new Error("Failed to fetch SSID list");
        }
      } catch (error) {
        console.error("Failed to fetch SSID list", error.message);
      } finally {
        setLoading(false);
        setRefreshing(false);
      }
    };
    fetchSsidList();
  }, []);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleSubmit = async () => {
    try {
      // Step 1: Call the `api/vlan/add/vlan` endpoint
      console.log('Step 1 payload:', {
        SerialNumber: formData.serialNumber,
        vlan: {
          igmpSnooping: "1",
          name: configNetData.vlanName,
          overrideTrafficPriority: "0",
          qosConfig: configNetData.networkType,
          trafficClass: "0",
          vlanId: configNetData.vlanId,
          vlanNwDesc: configNetData.description,
          vlanNwName: configNetData.networkName,
          vlanType: "6",
          voipOptimization: "0",
        },
      });
      const api = new allApi();
      const vlanResponse = await api.postRequest(
        "api/vlan/add/vlan",
        "",
        {
          SerialNumber: formData.serialNumber,
          vlan: {
            igmpSnooping: "1",
            name: configNetData.vlanName,
            overrideTrafficPriority: "0",
            qosConfig: configNetData.networkType,
            trafficClass: "0",
            vlanId: configNetData.vlanId,
            vlanNwDesc: configNetData.description,
            vlanNwName: configNetData.networkName,
            vlanType: "6",
            voipOptimization: "0",
          },
        }
      );
  
      if (!vlanResponse.ok) {
        throw new Error("Step 1 API request failed: " + (await vlanResponse.text()));
      }
  
      // Step 2: Call `api/vlan/add/accessPort` if Step 1 was successful
      console.log('Step 2 payload:', {
        vlanId: parseInt(formData.vlanId, 10),
        SerialNumber: formData.serialNumber,
        vlanMembers: {
          apPortMembers: [],
          employeeMembers: [],
          guestMembers: [],
          lagMembers: {
            portGrp: [],
            tagged: [],
            untagged: [],
          },
          lanPortMembers: [],
          memberWirelessNetwork: [],
          portMembers: [
            {
              deviceId: "667d4168cd6b7b3e023755e5",
              taggedPorts: formData.taggedPorts.split(",").map((port) => port.trim()),
              untaggedPorts: formData.untaggedPorts.split(",").map((port) => port.trim()),
            },
          ],
          publicMembers: [],
          wanPortMembers: [],
        },
      });
  
      const accessPortResponse = await api.postRequest(
        "api/vlan/add/accessPort",
        "",
        {
          vlanId: parseInt(formData.vlanId, 10),
          SerialNumber: formData.serialNumber,
          vlanMembers: {
            apPortMembers: [],
            employeeMembers: [],
            guestMembers: [],
            lagMembers: {
              portGrp: [],
              tagged: formData.taggedPorts.split(",").map((port) => port.trim()),
              untagged: formData.untaggedPorts.split(",").map((port) => port.trim()),
            },
            lanPortMembers: [],
            memberWirelessNetwork: [],
            portMembers: [
              {
                deviceId: "667d4168cd6b7b3e023755e5",
                taggedPorts: formData.taggedPorts.split(",").map((port) => port.trim()),
                untaggedPorts: formData.untaggedPorts.split(",").map((port) => port.trim()),
              },
            ],
            publicMembers: [],
            wanPortMembers: [],
          },
        }
      );
  
      if (!accessPortResponse.ok) {
        throw new Error("Step 2 API request failed: " + (await accessPortResponse.text()));
      }
  
      // Step 3: Call `api/wireless/configuration/addSsid` if Step 2 was successful
      console.log('Step 3 payload:', {
        ssid: formData.ssid,
        broadcastStatus: formData.broadcastStatus,
        band: formData.band.join(", "),
        vlanId: formData.vlanId,
        bandSteeringSt: formData.bandSteeringSt,
        fastRoamingSt: formData.fastRoamingSt,
        clientIsolation: formData.clientIsolation,
        AllowLocalUIAccess: formData.AllowLocalUIAccess,
        securityAuthentication: formData.securityAuthentication,
      });
  
      const ssidResponse = await api.postRequest(
        "api/wireless/configuration/addSsid?networkId=6001",
        "",
        {
          ssid: formData.ssid,
          broadcastStatus: formData.broadcastStatus,
          band: formData.band.join(", "),
          vlanId: formData.vlanId,
          bandSteeringSt: formData.bandSteeringSt,
          fastRoamingSt: formData.fastRoamingSt,
          clientIsolation: formData.clientIsolation,
          AllowLocalUIAccess: formData.AllowLocalUIAccess,
          securityAuthentication: formData.securityAuthentication,
        }
      );
  
      if (!ssidResponse.ok) {
        throw new Error("Step 3 API request failed: " + (await ssidResponse.text()));
      }
  
      alert("Submission successful!");
      // Navigate to the next page or show success message as needed
    } catch (error) {
      console.error("Submission failed:", error.message);
      alert("An error occurred while submitting the form. Please try again.");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };
  
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ConfigNet
            data={configNetData}
            setData={setConfigNetData}
            setIsValid={setIsStep1Valid}
          />
        );
      case 2:
        return (
          <WiredSettings
            data={wiredSettingsData}
            setData={setWiredSettingsData}
            basicSwitchInfo={basicSwitchInfo} 
            serialNumber={formData.serialNumber} 
          />
        );
      case 3:
        return (
          <WirelessSettings
            ssidList={ssidList} // Pass ssidList
            setData={setWirelessSettingsData}
          />
        );
      case 4:
        return (
          <IpConfiguration
            data={ipConfigurationData}
            setData={setIpConfigurationData}
          />
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (currentStep === 4) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    // Handle cancel action
    alert("Cancelled");
  };

  return (
    <div className="configure-network">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Configure Network</h3>
              </div>
              <div className="inc-card-body">
                <div className="inc-report">
                  <div className="report-tab">
                    {[1, 2, 3, 4].map((step) => (
                      <div
                        className={`step ${
                          currentStep === step
                            ? "selected"
                            : currentStep > step
                            ? "completed"
                            : ""
                        }`}
                        key={step}
                        onClick={() => handleStepClick(step)} // Ensure this is defined
                      >
                        <div
                          className="s-text"
                          style={{ cursor: "pointer", color: "purple" }}
                        >
                          <span>STEP {step}</span>
                          <strong>
                            {step === 1 && "Configure Network"}
                            {step === 2 && "Wired Settings"}
                            {step === 3 && "Wireless Settings"}
                            {step === 4 && "IP Configuration"}
                          </strong>
                        </div>
                        <div className="s-dot">
                          <i
                            className={`fa ${
                              currentStep >= step ? "fa-check" : "fa-circle"
                            }`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {step < 4 && (
                          <i className="line">
                            <span></span>
                          </i>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="report-section">
                    <div className="report-container">{renderStep()}</div>
                    <div className="report-button">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )}
                      {currentStep === 1 && (
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={handleNext}
                        disabled={currentStep === 1 ? !isStep1Valid : false}
                      >
                        {currentStep === 4 ? "Submit" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigureNetwork;
