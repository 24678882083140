import React, { useState, useEffect } from "react";
// import dummyGraph from "../../../assets/images/data-usage.png";
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import allApi from "../../../../api/allApi";
import ApexCharts from "react-apexcharts";

function WirelessDashboard() {
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [ClientSeries, setClientSeries] = useState([]);
  const [ClientDataCategories, setClientDataCategories] = useState([]);
  const [ChannelSeries, setChannelSeries] = useState([]);
  const [ChannelCategories, setChannelCategories] = useState([]);
  const [trafficSeries, setTrafficSeries] = useState([]);
  const [trafficCategories, setTrafficCategories] = useState([]);
  const [wirelessDataSeries, setWirelessDataSeries] = useState([]);
  const [wirelessDataCategories, setWirelessDataCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [devices, setDevices] = useState([]);
  const [clients, setClients] = useState([]);
  const [networkId, setNetworkId] = useState("all");
  const [duration, setDuration] = useState("24h");
  const [trafficDuration, setTrafficDuration] = useState("24h");
  const [wirelessDataDuration, setWirelessDataDuration] = useState("24h");
  const [clientsDuration, setClientsDuration] = useState("24h");
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");
  const [ClientDataConsumptionDuration, setClientDataConsumptionDuration] =
    useState("24h");
  const [channelUtilizationDuration, setChannelUtilizationDuration] =
    useState("24h");
  const [newDuration, setNewDuration] = useState("24h");

  useEffect(() => {
    getDeviceDetails();
    getClientList();
  }, []);

  useEffect(() => {
    getClientDetail(clientsDuration);
  }, [clientsDuration]);

  useEffect(() => {
    getConnectedClientPerSSID(connectedClientsDuration);
  }, [connectedClientsDuration]);

  useEffect(() => {
    getChannelUtilizationData(channelUtilizationDuration);
  }, [channelUtilizationDuration]);
  useEffect(() => {
    getTrafficBandwidthData(trafficDuration);
  }, [trafficDuration]);

  useEffect(() => {
    getWirelessDataConsumption(networkId, wirelessDataDuration);
  }, [networkId, wirelessDataDuration]);

  useEffect(() => {
    getClientDataConsumption(ClientDataConsumptionDuration);
  }, [ClientDataConsumptionDuration]);

  const handleDurationChange = (dur) => {
    setDuration(dur);
  };

  const handleTrafficDurationChange = (trafficDuration) => {
    setTrafficDuration(trafficDuration);
  };

  const handleWirelessDataDurationChange = (dur) => {
    setWirelessDataDuration(dur);
  };
  const handleClientsDurationChange = (clientsDuration) => {
    setClientsDuration(clientsDuration);
  };

  const handleConnectedClientsDurationChange = (connectedClientsDuration) => {
    setConnectedClientsDuration(connectedClientsDuration);
  };

  const handleChannelUtilizationDurationChange = (channelUtilizationDuration) => {
    setChannelUtilizationDuration(channelUtilizationDuration);
  };

  const handleClientDataConsumptionDurationChange = (newDuration) => {
    setClientDataConsumptionDuration(newDuration);
  };

  const handleNetworkTypeChange = (type) => {
    setNetworkId(type);
  };

  async function getDeviceDetails() {
    try {
      setLoading(true);
      setRefreshing(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/deviceList?networkId=6001"
      );
      if (response) {
        setDevices(response.data);
      }
    } catch (error) {
      console.error("Error fetching device data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }

  const getClientList = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/clientList?networkId=6001"
      );
      if (response && response.data) {
        setClients(response.data);
      } else {
        console.warn("No data found in response");
        setClients([]);
      }
    } catch (error) {
      setClients([]);
    } finally {
      setLoading(false);
    }
  };

  const getTrafficBandwidthData = async (trafficDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalBandWidth?commandType=5&duration=${trafficDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;

        // Prepare the series data for each serial number
        const seriesData = data.map((item) => ({
          name: item.serialNo,
          data: item.trafficData.map((trafficItem) => ({
            x: trafficItem.createdAt * 1000, // Convert to milliseconds
            y: trafficItem.totalTraffic       // Total traffic for the point
          }))
        }));

        // Gather unique timestamps
        const timestamps = seriesData.flatMap((series) =>
          series.data.map((point) => point.x)
        );
        const uniqueTimestamps = Array.from(new Set(timestamps)).sort(
          (a, b) => a - b
        );

        // Set the traffic categories and series for rendering
        setTrafficCategories(uniqueTimestamps);
        setTrafficSeries(seriesData);
      } else {
        console.error("Failed to fetch traffic data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching traffic data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getChannelUtilizationData = async (channelUtilizationDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalChannelUtilization?commandType=5&duration=${channelUtilizationDuration}&networkId=6001`
      );

      if (response.status === "OK" && response.data) {
        const data = response.data;
        const seriesData = [];
        const categories = new Set();

        data.forEach((device) => {
          const deviceSeries = {
            name: device.serialNo,
            data: []
          };

          // Loop through trafficData to extract all points
          device.trafficData.forEach((entry) => {
            const timestamp = entry.createdAt * 1000; // Convert to milliseconds
            const totalChannelUtil = entry.totalChannelUtil;

            deviceSeries.data.push({
              x: timestamp,
              y: totalChannelUtil
            });

            categories.add(timestamp);
          });

          seriesData.push(deviceSeries);
        });

        // Sort categories
        const sortedCategories = Array.from(categories).sort((a, b) => a - b);

        console.log("Final seriesData:", seriesData);
        console.log("Final categories:", sortedCategories);

        setChannelCategories(sortedCategories);
        setChannelSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching channel utilization data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getWirelessDataConsumption = async (networkId, duration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/wirelessDataConsumption?commandType=5&duration=${duration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;

        // Prepare series data for the chart
        const seriesData = data.map(device => {
          // Calculate total consumption for the device
          const totalConsumption = device.trafficData.reduce((sum, entry) => {
            return sum + entry.totalConsumption / 1024;
          }, 0);

          return {
            x: device.serialNo, // Use serial number for the x-axis
            y: totalConsumption.toFixed(2),  // Total consumption for the y-axis
          };
        });

        // Set categories and series data
        setWirelessDataCategories(data.map(device => device.serialNo)); // Serial numbers for categories
        setWirelessDataSeries([{ name: 'Total Consumption', data: seriesData }]); // Prepare series data for the chart
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching wireless data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getConnectedClientPerSSID = async (connectedClientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();

      // Fetch data with the specified duration
      const response = await api.getRequest(
        `api/wireless/connectedClientsPerSsid?commandType=2&duration=${connectedClientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;

        // Prepare series data for the chart
        const seriesData = data.map((item) => ({
          name: item.ssidName,
          data: item.clients.map(client => ({
            x: client.timestamp * 1000, // Convert timestamp to milliseconds
            y: client.totalClient, // Use totalClient for the y-axis
          })),
        }));

        // Extract unique timestamps for the x-axis categories
        const timeStamps = [...new Set(seriesData.flatMap(series => series.data.map(point => point.x)))];

        // Set categories and series data
        setConnectedClientCategories(timeStamps);
        setConnectedClientSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching connected client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getClientDetail = async (clientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalClients?commandType=5&duration=${clientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;
        const seriesData = {};

        data.forEach((item) => {
          const serialNo = item.serialNo;

          item.clients.forEach((client) => {
            const timestamp = client.timestamp * 1000; // Convert timestamp to milliseconds
            const totalClients = client.totalClient || 0; // Use 0 if totalClient is not present

            // Initialize series data for this serial number if not present
            if (!seriesData[serialNo]) {
              seriesData[serialNo] = {
                name: serialNo,
                data: []
              };
            }

            // Add data point for the current timestamp and total clients
            seriesData[serialNo].data.push({
              x: timestamp,
              y: totalClients
            });
          });
        });

        // Convert seriesData to an array of series objects
        const series = Object.values(seriesData);
        let categories = [];

        if (clientsDuration === '7d' || clientsDuration === '30d') {
          // Aggregate by day
          const dayData = {};
          series.forEach(serie => {
            serie.data.forEach(point => {
              const date = new Date(point.x).setHours(0, 0, 0, 0); // Normalize to the start of the day
              if (!dayData[date]) {
                dayData[date] = { x: date, y: 0 };
              }
              dayData[date].y += point.y; // Aggregate total clients
            });
          });
          categories = Object.keys(dayData).map(Number).sort((a, b) => a - b);
          series.forEach(serie => {
            serie.data = categories.map(date => ({
              x: date,
              y: dayData[date] ? dayData[date].y : 0
            }));
          });
        } else if (clientsDuration === '8h') {

          categories = [];
          series.forEach(serie => {

            categories = [...new Set([...categories, ...serie.data.map(point => point.x)])]; // Collect unique timestamps
          });
        } else if (clientsDuration === '24h') {

          const hourData = {};
          series.forEach(serie => {
            serie.data.forEach(point => {
              const hour = new Date(point.x).setMinutes(0, 0, 0);
              if (!hourData[hour]) {
                hourData[hour] = { x: hour, y: 0 };
              }
              hourData[hour].y += point.y; // Aggregate total clients
            });
          });
          categories = Object.keys(hourData).map(Number).sort((a, b) => a - b);
          series.forEach(serie => {
            serie.data = categories.map(hour => ({
              x: hour,
              y: hourData[hour] ? hourData[hour].y : 0
            }));
          });
        }

        // Set categories and series data
        setCategories(categories);
        setSeries(series);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client detail data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getClientDataConsumption = async (ClientDataConsumptionDuration) => {
    try {
        setLoading(true);
        const api = new allApi();
        const response = await api.getRequest(
            `api/wireless/clientDataConsumption?commandType=5&duration=${ClientDataConsumptionDuration}&networkId=6001`
        );

        if (response.status === "OK" && response.data) {
            const data = response.data;
            const series = [];

            data.forEach((client) => {
                const consumptionData = {
                    name: `${client.deviceName} (SN: ${client.serialNumber})`,
                    data: []
                };

                // Iterate through the consumption entries
                if (client.consumption) {
                    client.consumption.forEach((entry) => {
                        const timestamp = new Date(entry.timestamp * 1000).toISOString();
                        const totalConsumption = entry.totalConsumption;

                        // Add to series data
                        consumptionData.data.push({
                            x: timestamp,
                            y: totalConsumption
                        });
                    });
                }

                series.push(consumptionData);
            });

            // Sorting the data by timestamp if needed
            series.forEach(clientSeries => {
                clientSeries.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });

            setClientDataCategories(series[0]?.data.map(point => point.x)); // Assuming at least one client exists
            setClientSeries(series);
        } else {
            console.error("Failed to fetch data:", response.message);
        }
    } catch (error) {
        console.error("Error fetching connected client data:", error);
    } finally {
        setLoading(false);
    }
};
 
const clientDataConsumptionOptions = {
  chart: {
      type: "line",
      height: 350,
      toolbar: {
          show: false,
      },
  },
  xaxis: {
      type: "datetime",
      categories: ClientDataCategories,
      labels: {
          formatter: (value) => {
              const date = new Date(value);

              if (ClientDataConsumptionDuration === '7d' || ClientDataConsumptionDuration === '30d') {
                  const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
                  return date.toLocaleString('en-IN', options);
              } else {
                  const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
                  return date.toLocaleString('en-IN', options);
              }
          },
          style: {
              colors: '#9aa0ac',
              fontSize: '12px',
          },
      },
  },
  yaxis: {
      min: 0,
      labels: {
          formatter: function (val) {
              return val !== undefined && val !== null ? val.toFixed(0) : "0 bytes";
          },
      },
      title: {
          text: "Data Consumption (bytes)",
      },
  },
  stroke: {
      width: 1,
  },
  dataLabels: {
      enabled: false,
  },
  legend: {
      show: false, // Set to false to remove the legend
  },
  colors: ClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
  ),
  zoom: {
      enabled: false,
  },
  toolbar: {
      show: false,
  },
};

  const TrafficOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (trafficDuration === '7d' || trafficDuration === '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          // Convert the timestamp to a Date object and ensure it's in the correct timezone
          const date = new Date(value);
          const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
          return date.toLocaleString('en-IN', options); // Return only hours and minutes
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return Math.round(val); // Ensure whole numbers
        },
      },
      title: {
        text: "Traffic (KBPS)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  };

  const ChannelOptions = {
    chart: {
      id: "channel-utilization-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: ChannelCategories,
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (channelUtilizationDuration === '7d' || channelUtilizationDuration === '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? Math.round(val) : "N/A";
        },
      },
      title: {
        text: "Channel Utilization (%)",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ChannelSeries.map((_, index) => {
      // Assign different colors for each series
      const colorArray = ["#fc7594", "#00aaff", "#4caf50", "#ff9800"];
      return colorArray[index % colorArray.length];
    }),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };

  const WirelessDataOptions = {
    chart: {
      type: "bar", // Change to bar chart to show serial numbers vs consumption
      height: 350,
    },
    xaxis: {
      categories: wirelessDataCategories, // Serial numbers
    },
    yaxis: {
      min: 0, // Set minimum value to 0
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      title: {
        text: "Total Consumption (MB)",
      },
    },

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
  };

  const connectedClientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime", // Ensure x-axis is treated as datetime
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (connectedClientsDuration === '7d' || connectedClientsDuration === '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: function (value) {
          // Convert timestamp to a readable time format in tooltip
          const date = new Date(value);
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          // Format y-axis labels to two decimal places
          return val !== undefined ? val.toFixed(0) : "N/A";
        },
      },
      title: {
        text: "Client Count",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom", // Place the legend below the chart
      horizontalAlign: "center",
    },
    colors: ConnectedClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
  };

  const clientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly
          if (clientsDuration === '7d' || clientsDuration === '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly for tooltip
          if (clientsDuration === '7d' || clientsDuration === '30d') {
            const options = { day: '2-digit', month: 'short' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? val.toFixed(0) : "N/A"; // Use integer values
        },
      },
      title: {
        text: "Number of Clients",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    zoom: {
      enabled: true,
    },
  };

  return (
    <div>
      <div className="right-button-group">
        <div>
          <button
            className="text-btn primary-btn"
            onClick={() => {
              navigate("/wifi-config");
            }}
          >
            Wifi Configuration
          </button>
        </div>
      </div>
      <div className="two-column mb-4">
        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Clients
              <span
                onClick={() => {
                  navigate("/client-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {clientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : clientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {series.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <ApexCharts
                    options={clientsOptions}
                    series={series}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Traffic Bandwidth
              <span
                onClick={() => {
                  navigate("/trafficBandwidth-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {trafficDuration === "24h"
                      ? "Last 24 Hrs"
                      : trafficDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {trafficSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <ApexCharts
                    options={TrafficOptions}
                    series={trafficSeries}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Connected Clients per SSID
              <span
                onClick={() => {
                  navigate("/ConnectedClients-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {connectedClientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : connectedClientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {ConnectedClientSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={connectedClientsOptions}
                    series={ConnectedClientSeries}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Wireless Data Consumption
              <span
                onClick={() => {
                  navigate("/wirelessDataGraph-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {networkId === "all" ? "All" : networkId}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="clientDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("2.4GHz")}
                      >
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("5GHz")}
                      >
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("6GHz")}
                      >
                        6 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("all")}
                      >
                        All
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {wirelessDataDuration === "24h"
                      ? "Last 24 Hrs"
                      : wirelessDataDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {wirelessDataSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={WirelessDataOptions}
                    series={wirelessDataSeries}
                    type="bar"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Client Data Consumption
              <span
                onClick={() => {
                  navigate("/ClientData-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {ClientDataConsumptionDuration === "24h"
                      ? "Last 24 Hrs"
                      : ClientDataConsumptionDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleClientDataConsumptionDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {ClientSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={clientDataConsumptionOptions}
                    series={ClientSeries}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="inc-card">
          <div className="inc-card-title">
            <h3>
              Channel Utilization
              <span
                onClick={() => {
                  navigate("/ChannelUtilization-expandation");
                }}
              >
                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
              </span>
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {channelUtilizationDuration === "24h"
                      ? "Last 24 Hrs"
                      : channelUtilizationDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleChannelUtilizationDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
          <div className="inc-card-body">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {ChannelSeries.length === 0 ? (
                  <div className="no-data-box">
                    <div className="no-data-text">No Data Available</div>
                  </div>
                ) : (
                  <Chart
                    options={ChannelOptions}
                    series={ChannelSeries}
                    type="line"
                    height={170}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Client List</h3>
                <div className="inc-card-button">
                  <button
                    className="icon-btn"
                    title="Refresh"
                    onClick={getClientList}
                  >
                    {refreshing ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    )}
                  </button>
                  {/* <button className="icon-btn" title="Add">
                    <i className="fa fa-filter" aria-hidden="true"></i>
                  </button> */}
                </div>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Clients</th>
                        <th>Connection</th>
                        <th>Associated Device</th>
                        <th>Device Model</th>
                        <th>SSID</th>
                        <th>Port</th>
                        <th>OS</th>
                        <th>Mac Address</th>
                        <th>IP Address</th>
                        <th>RSSI</th>
                        <th>Resume/Pause</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : clients && clients.length > 0 ? (
                        clients.map((client, index) => (
                          <tr key={index}>
                            <td>{client.serialNo || "N/A"}</td>
                            <td>{client.port || "N/A"}</td>
                            <td>{client.serialNo || "N/A"}</td>
                            <td>{client.deviceName || "N/A"}</td>
                            <td>{client.ssid || "N/A"}</td>
                            <td>{client.port || "N/A"}</td>
                            <td>{client.platform || "N/A"}</td>
                            <td>{client.bssid || "N/A"}</td>
                            <td>{client.ip || "N/A"}</td>
                            <td>{client.rssi || "N/A"}</td>
                            <td>{client.resume || "N/A"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">No Clients found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Devices ({devices.length})</h3>
                <div className="inc-card-button">
                  <button
                    className="icon-btn"
                    title="Refresh"
                    onClick={getDeviceDetails}
                  >
                    {refreshing ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    )}
                  </button>
                  {/* <button className="icon-btn" title="Add">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button> */}
                </div>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Device Name</th>
                        <th>HW Version</th>
                        <th>FW Version</th>
                        <th>IP</th>
                        <th>Mac Address</th>
                        <th>UP Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : devices.length > 0 ? (
                        devices.map((device, index) => (
                          <tr key={index}>
                            <td>{device.serialNumber || "N/A"}</td>
                            <td>{device.deviceName || "N/A"}</td>
                            <td>{device.hardwareVersion || "N/A"}</td>
                            <td>{device.firmwareVersion || "N/A"}</td>
                            <td>{device.ipAddress || "N/A"}</td>
                            <td>{device.macAddress || "N/A"}</td>
                            <td>{device.created_at || "N/A"}</td>
                            <td>
                              <span
                                className={
                                  "d-status " +
                                  (device?.health?.toLowerCase() === "offline"
                                    ? "offline"
                                    : "online")
                                }
                              >
                                {device.health}
                              </span>{" "}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">No devices found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WirelessDashboard;
