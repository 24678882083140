import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";

function SystemSettings() {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState("light"); // State to manage theme

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Function to toggle theme between light and dark
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card ch-100">
            <div className="inc-card-title">
              <h3>System Information</h3>
              {/* Button to toggle between themes */}
              <button onClick={toggleTheme} className="btn btn-primary">
                {theme === "light" ? "Dark Mode" : "Light Mode"}
              </button>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                <div className="white-bg">
                  {/* Dynamically update iframe URL based on theme */}
                  <iframe
                    src={`https://inc.aws.vvdncloud.com:3001/d/jMrPmiSnk/docker-containers?orgId=1&refresh=10s&kiosk&theme=${theme}`}
                    width="100%"
                    height="800px"
                    frameBorder="0"
                    title="Docker Containers"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemSettings;

