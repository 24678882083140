import React, { useState, useEffect, useRef } from "react";
import locationMap from "../../../assets/images/map.png";
import ssidGraph from "../../../assets/images/ssid-graph.png";
import deviceImage from "../../../assets/images/device.png";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import towerIconSvg from "./tower.svg";
import "leaflet/dist/leaflet.css";
import Chart from "react-apexcharts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import StackedHorizontalChart from "../dashboard/StackedHorizontalChart";
import { useLocation } from "react-router-dom";

function DeviceDashboard(networkId) {
  const location = useLocation();
  const { deviceId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [usageDetails, setUsageDetails] = useState({});
  const [dataUsage, setDataUsage] = useState([]);
  const [apDataUsage, setApDataUsage] = useState([]);
  const [apInterval, setApInterval] = useState("7d");
  const [selectedInterval, setSelectedInterval] = useState("7d");
  const [upDownData, setUpDownData] = useState([]);
  const [colorRanges, setColorRanges] = useState([]);
  const initialDeviceData = location.state?.deviceInfo || {};
  const [deviceType, setDeviceType] = useState(
    initialDeviceData.deviceType || "N/A"
  );
  const [connectedClients, setConnectedClients] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");
  const nodes = [
    {
      id: "parent",
      label: "Tower",
      position: [28.4303577, 77.0125097],
      icon: towerIconSvg,
    },
  ];
  const createIcon = (iconUrl) =>
    L.icon({
      iconUrl: iconUrl,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });

  useEffect(() => {
    fetchDeviceDetails(deviceId);
    fetchCpuAndMemoryUsage(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (deviceType === "AP") {
      DataUsageAP(deviceId, networkId);
      fetchConnectedClientsPerSSID(deviceId);
    } else if (deviceType === "SW" || deviceType === "Switch") {
      fetchConnectedClients(deviceId);
      handleDataUsage(deviceId);
    }
  }, [deviceType, deviceId, selectedInterval, apInterval, networkId]);

  const fetchDeviceDetails = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/http/device/${deviceId}`);
      if (response) {
        setDeviceDetails(response);
        setDeviceType(response.deviceType);
      } else {
        console.error("No data returned from API");
      }
    } catch (error) {
      console.error("Error fetching device details:", error.message || error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCpuAndMemoryUsage = async (deviceId) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wired/dashboard/memoryCpuUtilization/${deviceId}`
      );
      if (response) {
        setUsageDetails({
          cpuUsage: response.cpuUsage,
          memoryUsage: response.memoryUsage,
        });
      } else {
        console.error("Failed to fetch device details.");
      }
    } catch (error) {
      console.error("Error fetching CPU and memory usage:", error);
    } finally {
      setLoading(false);
    }
  };

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split(":");
    const [hours, minutes] = timePart.split(":");
    return new Date(year, month - 1, day, hours, minutes);
  };

  const formatXAxisLabel = (date, interval) => {
    if (interval === "7d" || interval === "30d") {
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
      })}`;
    }
    return date.toLocaleTimeString();
  };

  const handleDataUsage = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wired/dashboard/dataUsage/${deviceId}/${selectedInterval}`
      );
      if (response) {
        const formattedData = response.map((entry) => ({
          date: parseDate(entry.created_at),
          totalTraffic: entry.total_traffic / 1000000, // Convert to MB
        }));
        setDataUsage(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data usage:", error);
    } finally {
      setLoading(false);
    }
  };

  const DataUsageAP = async (deviceId, networkId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/clientDataConsumption?duration=${apInterval}&networkId=6001&deviceId=${deviceId}`
      );
      if (response) {
        const formattedData = response.data.flatMap((entry) =>
          entry.consumption.map((consumption) => ({
            time: new Date(consumption.timestamp * 1000).toLocaleString(),
            rxBytes: consumption.rx,
            txBytes: consumption.tx,
            serialNumber: entry.serialNumber,
          }))
        );
        setApDataUsage(formattedData);
      }
    } catch (error) {
      console.error("Error fetching AP data usage:", error);
    } finally {
      setLoading(false);
    }
  };

  //   const fetchData = async (deviceId) => {
  //     setLoading(true);
  //     try {
  //       const api = new allApi();
  //       const response = await api.getRequest(`api/health/${deviceId}`);
  //       if (response) {
  //         const formattedData = response.map((entry) => ({
  //           updatedAt: new Date(entry.updatedAt).getTime(),
  //           status: entry.devHealth === 1 ? "online" : "offline",
  //         }));
  //         setUpDownData(formattedData);
  //         generateColorRanges(formattedData);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     }
  //   };

  const fetchConnectedClients = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/client/switchConnectedClients?deviceId=${deviceId}&networkId=6001`
      );
      if (response.wiredClients) {
        setConnectedClients(response.wiredClients);
      } else {
        setConnectedClients([]);
      }
    } catch (error) {
      console.error("Error fetching connected clients:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConnectedClientsPerSSID = async () => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/connectedClientsPerSsid?deviceId=${deviceId}&networkId=6001`
      );
      if (response.status === "OK") {
        const data = response.data;
        const seriesData = data.map((item) => ({
          name: item.ssidName,
          data: item.clients.map((client) => ({
            x: client.timestamp * 1000,
            y: client.totalClient,
          })),
        }));
        const timeStamps = [
          ...new Set(
            seriesData.flatMap((series) => series.data.map((point) => point.x))
          ),
        ];
        setConnectedClientCategories(timeStamps);
        setConnectedClientSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching connected client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateColorRanges = (data) => {
    let colorRanges = [];
    let lastState = data[0].status;
    let from = data[0].updatedAt;

    data.forEach((entry, index) => {
      if (entry.status !== lastState || index === data.length - 1) {
        colorRanges.push({
          from: from,
          to: entry.updatedAt,
          color: lastState === "online" ? "#00D76F" : "#fc3858",
        });
        from = entry.updatedAt;
        lastState = entry.status;
      }
    });

    setColorRanges(colorRanges);
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };
  const handleAPIntervalChange = (interval) => {
    setApInterval(interval);
  };

  const connectedClientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          const date = new Date(value);
          if (
            connectedClientsDuration === "7d" ||
            connectedClientsDuration === "30d"
          ) {
            const options = {
              day: "2-digit",
              month: "short",
              timeZone: "Asia/Kolkata",
            };
            return date.toLocaleString("en-IN", options);
          } else {
            const options = {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Asia/Kolkata",
              hour12: false,
            };
            return date.toLocaleString("en-IN", options);
          }
        },
        style: {
          colors: "#9aa0ac",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: true,
        color: "#d6d6d6",
      },
      axisTicks: {
        show: true,
        color: "#d6d6d6",
      },
      tooltip: {
        enabled: true,
        formatter: function (value) {
          const date = new Date(value);
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          return `${hours}:${minutes}`;
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? val.toFixed(0) : "N/A";
        },
      },
      title: {
        text: "Client Count",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ConnectedClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                {deviceDetails && (
                  <div className="dashboard-device-icon">
                    <img src={deviceImage} alt="" />
                    <strong>{deviceDetails?.deviceName || "N/A"}</strong>
                  </div>
                )}

                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceDetails?.serialNumber || "N/A"}</strong>
                      <span>Serial Number</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.ipAddress || "N/A"}</strong>
                      <span>IP Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.macAddress || "N/A"}</strong>
                      <span>Mac Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.firmwareVersion || "N/A"}</strong>
                      <span>Firmware version</span>
                    </div>
                  </div>
                  <div className="detail-list">
                    <div>
                      <strong>{usageDetails?.cpuUsage || "N/A"}</strong>{" "}
                      <span>CPU Utilization</span>
                    </div>
                    <div>
                      <strong>{usageDetails?.memoryUsage || "N/A"}</strong>{" "}
                      <span>Memory Utilization</span>
                    </div>
                    <div>
                      <strong>{deviceDetails && deviceDetails.health}</strong>
                      <span>Device Health</span>
                    </div>
                  </div>
                  <div className="mt-3">
                    {" "}
                    <strong>Up/Down Time</strong>
                  </div>

                  <div className="graph">
                    {upDownData.length === 0 ? (
                      <div>No data to display</div>
                    ) : (
                      <div>
                        {loading ? (
                          <Loader />
                        ) : (
                          <StackedHorizontalChart
                            height={80}
                            data={upDownData}
                            colorRanges={colorRanges}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Data Usage for Switch*/}
      {(deviceType === "SW" || deviceType === "Switch") && (
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Data Usage</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedInterval}{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("6h")}
                      >
                        6 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("12h")}
                      >
                        12 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("24h")}
                      >
                        24 Hours
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("7d")}
                      >
                        7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleIntervalChange("30d")}
                      >
                        30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : dataUsage.length === 0 ? (
                  <div className="no-data-box">No data to display</div>
                ) : (
                  <div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={dataUsage}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={(date) =>
                            formatXAxisLabel(date, selectedInterval)
                          }
                        />
                        <YAxis tickFormatter={(value) => `${value} MB`} />
                        <Tooltip formatter={(value) => [`${value} MB`]} />
                        <Line
                          type="monotone"
                          dataKey="totalTraffic"
                          stroke="#4791FF"
                          name="Total Traffic"
                          strokeWidth={2}
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="data-label">
                      <span className="download">
                        <i></i>Download
                      </span>
                      <span className="upload">
                        <i></i>Upload
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Connected Clients</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="inc-card-table">
                    <table className="full-table">
                      <thead>
                        <tr>
                          <th>Device Name</th>
                          <th>MAC Address</th>
                          <th>IP Address</th>
                          <th>Port</th>
                          <th>Platform</th>
                        </tr>
                      </thead>
                      <tbody>
                        {connectedClients.length > 0 ? (
                          connectedClients.map((client) => (
                            <tr key={client.clientId}>
                              <td>{client.deviceName}</td>
                              <td>{client.macAddress}</td>
                              <td>{client.ipAddress}</td>
                              <td>{client.port}</td>
                              <td>{client.platform}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No connected clients found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Data Usage for AP*/}
      {deviceType === "AP" && (
        <div className="row mb-4">
          <div className="col-lg-7">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Client Data Usage</h3>
                <span
                  className="dropdown"
                  id="trendDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {apInterval}{" "}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="trendDropdown"
                  >
                    {["6h", "12h", "24h", "7d", "30d"].map((interval) => (
                      <li key={interval}>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleAPIntervalChange(interval)}
                        >
                          {interval}
                        </a>
                      </li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : apDataUsage.length === 0 ? (
                  <div className="no-data-box">No data to display</div>
                ) : (
                  <div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={apDataUsage}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis tickFormatter={(value) => `${value} MB`} />
                        <Tooltip
                          formatter={(value, name) => [`${value} MB`, name]}
                        />
                        <Line
                          type="monotone"
                          dataKey="txBytes"
                          stroke="#FF9050"
                          name="Upload (txBytes)"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="rxBytes"
                          stroke="#4791FF"
                          name="Download (rxBytes)"
                          strokeWidth={1}
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <div className="data-label">
                      <span className="download">
                        <i></i>Download
                      </span>
                      <span className="upload">
                        <i></i>Upload
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Connected Client Per SSID</h3>
              </div>
              <div className="inc-card-body">
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ConnectedClientSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={connectedClientsOptions}
                        series={ConnectedClientSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Device Location</h3>
            </div>
            <div className="inc-card-body">
              <div>
                <MapContainer
                  center={[28.4303577, 77.0125097]}
                  zoom={12}
                  style={{ height: "350px", width: "100%" }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {nodes.map((node) => (
                    <Marker
                      key={node.id}
                      position={node.position}
                      icon={createIcon(deviceImage)}
                    ></Marker>
                  ))}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDashboard;
