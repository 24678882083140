import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useEffect, useState } from "react";

function ConnectedClientExpandation() {
  const [ConnectedClientSeries, setConnectedClientSeries] = useState([]);
  const [ConnectedClientCategories, setConnectedClientCategories] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [connectedClientsDuration, setConnectedClientsDuration] =
    useState("24h");

  useEffect(() => {
    getConnectedClientPerSSID(connectedClientsDuration);
  }, [connectedClientsDuration]);

  const getConnectedClientPerSSID = async (connectedClientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();

      // Fetch data with the specified duration
      const response = await api.getRequest(
        `api/wireless/connectedClientsPerSsid?commandType=2&duration=${connectedClientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;

        // Prepare series data for the chart
        const seriesData = data.map((item) => ({
          name: item.ssidName,
          data: item.clients.map(client => ({
            x: client.timestamp * 1000, // Convert timestamp to milliseconds
            y: client.totalClient, // Use totalClient for the y-axis
          })),
        }));

        // Extract unique timestamps for the x-axis categories
        const timeStamps = [...new Set(seriesData.flatMap(series => series.data.map(point => point.x)))];

        // Set categories and series data
        setConnectedClientCategories(timeStamps);
        setConnectedClientSeries(seriesData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching connected client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const connectedClientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime", // Ensure x-axis is treated as datetime
      labels: {
        formatter: (value) => {
          debugger
          const date = new Date(value);

          // Check the duration and format accordingly
          if (connectedClientsDuration == '7d' || connectedClientsDuration == '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            console.log("option1", options);
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"

          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            console.log("option2", options);
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: function (value) {
          // Convert timestamp to a readable time format in tooltip
          const date = new Date(value);
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          // Format y-axis labels to two decimal places
          return val !== undefined ? val.toFixed(0) : "N/A";
        },
      },
      title: {
        text: "Client Count",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom", // Place the legend below the chart
      horizontalAlign: "center",
    },
    colors: ConnectedClientSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
  };


  const handleConnectedClientsDurationChange = (newDuration) => {
    setConnectedClientsDuration(newDuration);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Connected Clients per SSID
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {connectedClientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : connectedClientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("8h")
                        }
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("24h")
                        }
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("7d")
                        }
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleConnectedClientsDurationChange("30d")
                        }
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {ConnectedClientSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={connectedClientsOptions}
                        series={ConnectedClientSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>SSID Name</th>
                          <th>Client Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ConnectedClientSeries && ConnectedClientSeries.length > 0 &&
                          ConnectedClientSeries.flatMap((seriesItem, seriesIndex) =>
                            seriesItem.data.map((dataItem, dataIndex) => (
                              <tr key={`${seriesIndex}-${dataIndex}`}>
                                <td>{seriesItem.name}</td>
                                {/* <td>Serial {dataItem.x}</td> Serial number */}
                                <td>{dataItem.y}</td> {/* Client count */}
                              </tr>
                            ))
                          )
                        }
                      </tbody>


                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectedClientExpandation;
