import React, { useState, useEffect } from "react";
import deviceone from "../../../assets/images/r1.png";
import devicetwo from "../../../assets/images/r2.png";
import devicethree from "../../../assets/images/r3.png";
import devicefour from "../../../assets/images/r4.png";
import allApi from "../../../api/allApi";

function HTTPS() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [models, setModels] = useState([]);

  const handleAddModelClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest("api/model/allModels");
        setModels(response);
      } catch (error) {}
    };

    fetchModels();
  }, []);

  const handleSave = () => {
    // Add your save logic here
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>HTTPS Management</h3>
            <div className="inc-card-button">
              <button
                className="text-btn primary-btn"
                onClick={handleAddModelClick}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add
              </button>

              <button class="icon-btn" title="Refresh">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button class="icon-btn" title="Delete">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className="inc-card-table">
              <table className="full-table m-head device-pic">
                <thead>
                  <tr>
                    <th>
                      <label className="checkbox">
                        <input type="checkbox" />
                        <span className="indicator"></span>
                      </label>
                    </th>
                    <th colSpan={2}>Model Name</th>
                    <th>Device Type</th>
                    <th>Device communication protocol</th>
                    <th>Device monitoring interval</th>
                    <th>Device health interval</th>
                    <th>Current FW</th>
                    <th>Capabilities</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {models.map((model) => (
                    <tr key={model.id}>
                      <td>
                        <label className="checkbox">
                          <input type="checkbox" />
                          <span className="indicator"></span>
                        </label>
                      </td>
                      <td>
                        <img src={deviceone} alt="Device" />
                      </td>
                      <td>{model.name}</td>
                      <td>{model.deviceType}</td>
                      <td>{model.protocol}</td>
                      <td>{model.monitoringInterval}</td>
                      <td>{model.healthInterval}</td>
                      <td>{model.currentFW}</td>
                      <td>{model.capabilities}</td>
                      <td>
                        <div className="button-group">
                          <button title="Edit">
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {isModalOpen && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Model</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleCancel}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-ssid-body">
                      <form>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="ModelName"
                              className="col-form-label"
                            >
                              Model Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ModelName"
                              placeholder="Model Name"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceType"
                              className="col-form-label"
                            >
                              Device Type
                            </label>
                            <select
                              className="form-select"
                              name="DeviceType"
                              placeholder="Device Type"
                            >
                              <option>Router</option>
                              <option>Switches</option>
                              <option>Access Points</option>
                              <option>Firewalls</option>
                            </select>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="Protocol"
                              className="col-form-label"
                            >
                              Device Communication Protocol
                            </label>
                            <select
                              className="form-select"
                              name="Protocol"
                              placeholder="Protocol"
                            >
                              <option>Router</option>
                              <option>Switches</option>
                              <option>Access Points</option>
                              <option>Firewalls</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="DeviceImage"
                              className="col-form-label"
                            >
                              Device Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="DeviceImage"
                              placeholder="DeviceImage"
                            />
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="MonitoringInterval"
                              className="col-form-label"
                            >
                              Device Monitoring Interval
                            </label>
                            <select
                              className="form-select"
                              name="MonitoringInterval"
                              placeholder="MonitoringInterval"
                            >
                              <option>1 Min</option>
                              <option>5 Min</option>
                              <option>10 Min</option>
                              <option>15 Min</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="HealthInterval"
                              className="col-form-label"
                            >
                              Device Health Interval
                            </label>
                            <select
                              className="form-select"
                              name="HealthInterval"
                              placeholder="HealthInterval"
                            >
                              <option>1 Min</option>
                              <option>5 Min</option>
                              <option>10 Min</option>
                              <option>15 Min</option>
                            </select>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label
                              htmlFor="CurrentFW"
                              className="col-form-label"
                            >
                              Current FW
                            </label>
                            <select
                              className="form-select"
                              name="CurrentFW"
                              placeholder="CurrentFW"
                            >
                              <option>1.0.0.0</option>
                              <option>2.0.0.0</option>
                              <option>1.4.0.0</option>
                              <option>5.1.0.1</option>
                            </select>
                          </div>
                        </div>

                        <div className="border-line"></div>

                        <h4 className="mt-2">Capabilities</h4>
                        <div className="row d-flex">
                          <div className="col-lg-6">
                            <label htmlFor="port" className="col-form-label">
                              Suppoerted Port
                            </label>
                            <select
                              className="form-select"
                              name="port"
                              placeholder="port"
                            >
                              <option>8</option>
                              <option>12</option>
                              <option>16</option>
                              <option>32</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="ClientSupport"
                              className="col-form-label"
                            >
                              MAX Client Support
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="ClientSupport"
                              placeholder="ClientSupport"
                            />
                          </div>
                          <div className="col-lg-6">
                            <label>&nbsp;</label>
                            <div className="switch-box">
                              <strong>Radio Enabled</strong>
                              <label className="ice-switch">
                                <input type="checkbox" name="Radio" />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HTTPS;
