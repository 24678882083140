import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ClientExpandation() {
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [clientsDuration, setClientsDuration] = useState("24h");
  const [duration, setDuration] = useState("24h");
  useEffect(() => {
    getClientDetail(clientsDuration);
  }, [clientsDuration]);

  const getClientDetail = async (clientsDuration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalClients?commandType=5&duration=${clientsDuration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;
        const seriesData = {};

        data.forEach((item) => {
          const serialNo = item.serialNo;

          item.clients.forEach((client) => {
            const timestamp = client.timestamp * 1000; // Convert timestamp to milliseconds
            const totalClients = client.totalClient || 0; // Use 0 if totalClient is not present

            // Initialize series data for this serial number if not present
            if (!seriesData[serialNo]) {
              seriesData[serialNo] = {
                name: serialNo,
                data: []
              };
            }

            // Log date and hour for each client data point
            const date = new Date(timestamp);
            console.log(`Serial No: ${serialNo}, Date: ${date.toLocaleDateString('en-IN')}, Time: ${date.toLocaleTimeString('en-IN')}`);

            // Add data point for the current timestamp and total clients
            seriesData[serialNo].data.push({
              x: timestamp,
              y: totalClients
            });
          });
        });

        // Convert seriesData to an array of series objects
        const series = Object.values(seriesData);
        let categories = [];

        if (clientsDuration === '7d' || clientsDuration === '30d') {
          // Aggregate by day
          const dayData = {};
          series.forEach(serie => {
            serie.data.forEach(point => {
              const date = new Date(point.x).setHours(0, 0, 0, 0); // Normalize to the start of the day
              if (!dayData[date]) {
                dayData[date] = { x: date, y: 0 };
              }
              dayData[date].y += point.y; // Aggregate total clients
            });

          });
          categories = Object.keys(dayData).map(Number).sort((a, b) => a - b);
          series.forEach(serie => {
            serie.data = categories.map(date => ({
              x: date,
              y: dayData[date] ? dayData[date].y : 0
            }));
          });
        } else if (clientsDuration === '8h') {
          categories = [];
          series.forEach(serie => {
            categories = [...new Set([...categories, ...serie.data.map(point => point.x)])]; // Collect unique timestamps
          });
        } else if (clientsDuration === '24h') {
          const hourData = {};
          series.forEach(serie => {
            serie.data.forEach(point => {
              const hour = new Date(point.x).setMinutes(0, 0, 0);
              if (!hourData[hour]) {
                hourData[hour] = { x: hour, y: 0 };
              }
              hourData[hour].y += point.y; // Aggregate total clients
            });
          });
          categories = Object.keys(hourData).map(Number).sort((a, b) => a - b);
          series.forEach(serie => {
            serie.data = categories.map(hour => ({
              x: hour,
              y: hourData[hour] ? hourData[hour].y : 0
            }));
          });
        }

        // Set categories and series data
        setCategories(categories);
        setSeries(series);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client detail data:", error);
    } finally {
      setLoading(false);
    }
  };



  const clientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          debugger
          const date = new Date(value);

          // Check the duration and format accordingly
          if (clientsDuration == '7d' || clientsDuration == '30d') {
            const options = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
            console.log("option1", options);
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"

          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            console.log("option2", options);
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          const date = new Date(value);

          // Check the duration and format accordingly for tooltip
          if (clientsDuration === '7d' || clientsDuration === '30d') {
            const options = { day: '2-digit', month: 'short' };
            return date.toLocaleString('en-IN', options); // Format: "dd MMM"
          } else {
            const options = { hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata', hour12: false };
            return date.toLocaleString('en-IN', options); // Format: "HH:mm"
          }
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return val !== undefined ? val.toFixed(0) : "N/A"; // Use integer values
        },
      },
      title: {
        text: "Number of Clients",
      },
    },
    stroke: {
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    zoom: {
      enabled: true,
    },
  };


  const handleClientsDurationChange = (newDuration) => {
    setClientsDuration(newDuration);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Clients
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {clientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : clientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {series.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={clientsOptions}
                        series={series}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>No of Clients</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series && series.length > 0 &&
                          series.flatMap((seriesItem) => {
                            // Create a map to aggregate client counts by date
                            const dateToClientCountMap = seriesItem.data.reduce((acc, dataItem) => {
                              const dateStr = new Date(dataItem.x).toLocaleDateString(); // Format date as needed
                              if (!acc[dateStr]) {
                                acc[dateStr] = 0;
                              }
                              acc[dateStr] += dataItem.y; // Aggregate client counts
                              return acc;
                            }, {});

                            // Map to table rows
                            return Object.entries(dateToClientCountMap).map(([date, count], index) => (
                              <tr key={`${seriesItem.name}-${index}`}>
                                <td>{date}</td> {/* Date */}
                                <td>{count}</td> {/* Total number of clients */}
                              </tr>
                            ));
                          })
                        }
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientExpandation;
