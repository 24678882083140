import React from 'react'
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";

function WirelessDataExpandation() {
  const [wirelessDataSeries, setWirelessDataSeries] = useState([]);
  const [wirelessDataCategories, setWirelessDataCategories] = useState([]);
  const [wirelessDataDuration, setWirelessDataDuration] = useState("24h");
  const [loading, setLoading] = useState(true);
  const [networkId, setNetworkId] = useState("all");

  useEffect(() => {
    getWirelessDataConsumption(networkId, wirelessDataDuration);
  }, [networkId, wirelessDataDuration]);

  const getWirelessDataConsumption = async (networkId, duration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/wirelessDataConsumption?commandType=5&duration=${duration}&networkId=6001`
      );

      if (response.status === "OK") {
        const data = response.data;

        // Prepare series data for the chart
        const seriesData = data.map(device => {
          // Calculate total consumption for the device
          const totalConsumption = device.trafficData.reduce((sum, entry) => {
            return sum + entry.totalConsumption / 1024;
          }, 0);

          return {
            x: device.serialNo, // Use serial number for the x-axis
            y: totalConsumption.toFixed(2),  // Total consumption for the y-axis
          };
        });

        // Set categories and series data
        setWirelessDataCategories(data.map(device => device.serialNo)); // Serial numbers for categories
        setWirelessDataSeries([{ name: 'Total Consumption', data: seriesData }]); // Prepare series data for the chart
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching wireless data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNetworkTypeChange = (type) => {
    setNetworkId(type);
  };
  const handleWirelessDataDurationChange = (dur) => {
    setWirelessDataDuration(dur);
  };

  const WirelessDataOptions = {
    chart: {
      type: "bar", // Change to bar chart to show serial numbers vs consumption
      height: 350,
    },
    xaxis: {
      categories: wirelessDataCategories, // Serial numbers
    },
    yaxis: {
      min: 0, // Set minimum value to 0
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
      title: {
        text: "Total Consumption (MB)",
      },
    },

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Wireless Data Consumption
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {networkId === "all" ? "All" : networkId}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="clientDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("2.4GHz")}
                      >
                        2.4 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("5GHz")}
                      >
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("6GHz")}
                      >
                        6 GHz
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleNetworkTypeChange("all")}
                      >
                        All
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {wirelessDataDuration === "24h"
                      ? "Last 24 Hrs"
                      : wirelessDataDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleWirelessDataDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {wirelessDataSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={WirelessDataOptions}
                        series={wirelessDataSeries}
                        type="bar"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th> Total Data Usage</th>

                        </tr>
                      </thead>
                      <tbody>
                        {wirelessDataSeries && wirelessDataSeries.length > 0 &&
                          wirelessDataSeries.map((item, index) => {
                            const totalData = item.data.reduce((total, entry) => total + entry.y, 0);
                            return (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{totalData}</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WirelessDataExpandation;
